import React from 'react'
import _ from 'lodash'
import { Rating } from '../../../../ui'

const Ratting = ({ products, styles }) => (
  <>
    <div className={styles.row}>
      <div className={styles.columnTitle}>
        <h4>{_.get(products, 'rattings.title', '')}</h4>
      </div>
    </div>
    <div className={styles.row}>
      { products.rattings
            && _.map(products.rattings.values, (rattingValue, idx) => (
              <div className={styles.columnValue} key={`ratting-value-${idx}`}>
                {rattingValue !== undefined ? <Rating rate={parseFloat(rattingValue)} editing={false} /> : ''}
              </div>
            ))}
    </div>
  </>
)

export default Ratting
