/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import split from 'lodash/fp/split'
import map from 'lodash/fp/map'
import toInteger from 'lodash/fp/toInteger'

import React, {
  useEffect, useCallback, useState, useMemo,
} from 'react'
import URI from 'urijs'
import {
  useSkus,
  useSystemSettings,
  useProductDetailAttr,
} from 'react-omnitech-api'
import { useLink } from '../../hook/use-link'
import { t, isBrowser } from '../../helpers'
import {
  useCompare,
} from '../../hook'
import ProductsComparisonView from './products-comparison-view'

const ProductsComparisonController = ({ location }) => {
  const urlParams = useMemo(() => {
    let search = {}
    if (isBrowser()) {
      search = URI(location.href).search(true)
    }
    return search
  }, [location])
  // internal state
  const [productDetailAttributte, setProductDetailAttributte] = useState([])
  const [productObjToCompare, setProductObjToCompare] = useState([])
  const [skus, setSkus] = useState([])
  const [pageReady, setPageReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [ids, setIds] = useState([])

  // prepare hook
  const { fetchSkus } = useSkus()
  const { navigate } = useLink()
  const { getSystemSetting } = useSystemSettings()
  const { productDetailAttributeOptions } = useProductDetailAttr()
  const {
    compareData,
    hasMoreThan,
    maxNumberComparisonEcom,
    addItemsToCompare,
    clearItemToCompare,
  } = useCompare()
  const themeConfig = getSystemSetting('theme.ecom.config')

  /*
  * set Product detail Attr from cache
  * */

  const getProductDetailAttrApi = () => {
    setProductDetailAttributte(productDetailAttributeOptions)
  }

  /**
   * fetchSkusApi
   * get sku search result from API
   */
  const fetchSkusApi = useCallback(async (idsParams) => {
    try {
      // api call option
      const extraParams = getSystemSetting('api.v2.skus.index.product_search.ecom.query', {})
      // TODO: need to configurable for distinct by `p`, `pc`, `pcs`
      setIsLoading(true)
      const option = {
        includes: [
          'product',
          'products.product_detail_attributes',
          'products.reviews_summary',
          'color_option',
          'color_options.active_custom_labels',
          'color_options.favourite',
          'color_options.price_details',
          'color_options.stock_level',
          'products.categories',
          'categories.department',
          'reviews_summary',
          'reviews.user_default',
        ].join(','),
        distinct: 'pc',
        ...extraParams,
        // force to use distinct: 'pc' if PLP swatch is enabled
        ...(
          _.isEqual(_.get(extraParams, 'distinct'), 'p')
          && _.get(themeConfig, 'config.enablePlpSwatch', false)
            ? {
              distinct: 'pc',
            }
            : {}
        ),
        id: idsParams,
      }

      // call api
      const { skus: skusData, pagination: paginationData } = await fetchSkus(option)
      setSkus(_.concat(skusData, _.range(maxNumberComparisonEcom - skusData.length)))
    } catch (error) {
      // TODO: handle error
    } finally {
      setIsLoading(false)
      setPageReady(true)
    }
  }, [fetchSkus])

  const getAttributteInSkus = () => {
    const attr = _.map(skus, (sku) => {
      const attributesCode = _.reduce(_.get(sku, 'product.productDetailAttributes', ''), (_result, _value, _key) => {
        _value.code ? _result.push(_value.code) : ''
        return _result
      }, [])
      return attributesCode
    })

    return _.uniq(_.flatMap(attr))
  }
  const list = getAttributteInSkus()

  const productsToCompare = (listAttr) => ({
    products: skus,
    rattings: {
      title: t('screens.productComparison.titleRating'),
      values: getRankings(),
    },
    features: _.map(listAttr, (feature) => ({
      title: feature.label,
      values: getInfoSkus(feature.code),
    })),
  })

  const getRankings = () => (_.map(skus, (sku) => _.result(sku, 'product.reviewsAverageRating')))

  const getInfoSkus = (code) => {
    const details = _.map(skus, (sku) => {
      const deatils = sku.id ? sku.product.productDetailAttributes : null
      return _.filter(deatils, (detail) => detail.code === code)[0]
    })
    return details
  }

  function handleRemoveItem(item) {
    const paramsUrl = _.filter(ids, (e) => e !== item.id)
    navigate(`/products-comparison/?id[]=${paramsUrl}`)
  }

  function getObjProduct(item, isInStore = false) {
    return {
      id: _.get(item, 'id', ''),
      productId: isInStore ? _.get(item, 'productId') : _.get(item, 'product.id'),
      image: isInStore ? _.get(item, 'image')
        : _.get(item, 'colorOption.defaultImage.versions.webThumb', ''),
    }
  }

  const {
    shareUrl,
    shareImageUrl,
  } = useMemo(() => ({
    shareUrl: location.href,
    shareImageUrl: _.get(skus, '[0].colorOption.defaultImage.versions.webMedium'),
  }), [skus])

  useEffect(() => {
    const listAttr = _.filter(productDetailAttributte, (attr) => _.includes(getAttributteInSkus(), attr.code))
    const obj = productsToCompare(listAttr)
    setProductObjToCompare(obj)
  }, [skus])

  useEffect(() => {
    getProductDetailAttrApi()
  }, [])

  useEffect(() => {
    const idsUrl = flow(
      get('id[]'),
      split(','),
      map((id) => toInteger(id)),
    )(urlParams)
    if (!_.isEmpty(idsUrl)) {
      setIds(idsUrl)
      fetchSkusApi(idsUrl)
    }
  }, [urlParams])

  useEffect(() => {
    clearItemToCompare()
    const itemToCompare = _.compact(_.map(skus, (sku) => sku.id && getObjProduct(sku)))
    addItemsToCompare(itemToCompare)
  }, [skus])

  const viewProps = {
    compareData,
    hasMoreThan,
    isLoading,
    shareUrl,
    shareImageUrl,
    maxNumberComparisonEcom,
    pageReady,
    productObjToCompare,
    onAddToCompare: handleRemoveItem,
    onClearCompare: clearItemToCompare,
  }

  return (
    <ProductsComparisonView {...viewProps} />
  )
}

export default ProductsComparisonController
