/* eslint-disable no-unused-vars */
import { createUseStyles } from 'react-jss'
import ratioCalc from '../../../../helpers/ratioImage'

const useStyles = createUseStyles((theme) => ({
  container: {
    position: 'relative',
    // display: 'flex',
    // flexDirection: 'column',
  },
  containerSticky: {
    height: 100,
    // flexDirection: 'row',
  },
  boxImage: {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    transition: 'all 1500ms',
    margin: 0,
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      // height: 260,
    },
  },
  image: {
    height: 0,
    paddingBottom: ratioCalc(theme.config.ratioPlp),
    position: 'relative',
    backgroundColor: '#dddddd',
    backgroundClip: 'content-box',
    overflowY: 'hidden',
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: ' translate(-50%, -50%)',
      margin: 0,
      width: '100%',
    },
  },
  noImage: {
    backgroundClip: 'initial',
    '& img': {
      width: '40% !important',
      height: '40% !important',
    },
  },
  boxContent: {
    padding: [[0, 20]],
    position: 'absolute',
    top: 160,
    display: 'block',
    width: '100%',
    height: 100,
    transition: 'all 1000ms',
    textAlign: 'center',
    color: theme.colors.text,
    '& p, & p span': {
      marginBottom: '0',
      fontSize: '1.4rem',
      lineHeight: 1,
      margin: 0,
    },
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      '& p span': {
        marginBottom: '0',
        fontSize: '1.4rem',
        lineHeight: 1.40,
        margin: 0,
      },
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      top: 260,
    },
  },
  title: {
    fontSize: '1.6rem',
    color: theme.colors.text,
  },
  colorSelect: {
    margin: '5px 0',
    color: theme.colors.disabled,
  },
  colorSelectSticky: {
    display: 'none',

  },
  customLabels: {
    minHeight: '2rem',
    marginBottom: '0.4rem',
    marginTop: '1rem',
  },
  customLabelsSticky: {
    display: 'none',
  },
  originalPrice: {
    fontSize: '1.2rem',
    display: 'inline-block',
    textDecoration: 'line-through',
    color: theme.colors.disabled,
    lineHeight: 1.14,
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginRight: '0.4rem',
      display: 'inline',
      lineHeight: 1.8,
    },
  },
  sellPriceOnSale: {
    lineHeight: 1,
    color: theme.colors.alert,
    display: 'inline-block',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      lineHeight: 1.8,
    },
  },
}))

export default useStyles
