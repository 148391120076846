import _ from 'lodash'
import React from 'react'
import { Link } from '../../../../ui'

const Footer = ({
  styles, products, handleGoToDetail, t,
}) => (
  <div className={styles.row}>
    {
      _.map(products.products, (item, idx) => (
        <div className={styles.boxButton} key={idx}>
          {item.id && (
          <Link
            to="/"
            className={styles.buttonView}
            onClick={(e) => handleGoToDetail(e, item)}
          >
            <p className={styles.textButton}>{t('screens.productsComparison.btnDetail')}</p>
          </Link>
          )}
        </div>
      ))
    }
  </div>
)

export default Footer
