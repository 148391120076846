import React from 'react'
import {
  Button,
} from '../../../../ui'

const EmptyPage = ({ styles, handleContinueShopping, t }) => (
  <div className={styles.containerEmpty}>
    <div className={styles.isEmptyStyle}>
      <h4 className={styles.titleCardStyle}>{t('screens.productsComparison.title')}</h4>

      <p>{t('screens.productsComparison.empty')}</p>
      <Button
        text={t('screens.productsComparison.button')}
        dark
        className={styles.button}
        width="37rem"
        onClick={handleContinueShopping}
      />
    </div>
  </div>
)

export default EmptyPage
