/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import _ from 'lodash'
import React, {
  useEffect, useState, useRef,
} from 'react'
import { StickyContainer, Sticky } from 'react-sticky';
import { useTranslation, Trans } from 'react-i18next'
import { getProductUrl } from '../../helpers'
import { useLink } from '../../hook'
import useStyles from './products-comparison-style'
import combineClassNames from '../../helpers/combineClassNames'
import {
  Products,
  Ratting,
  Features,
  Footer,
  EmptyPage,
  RowProductsTop,
} from './components'
import {
  Layout,
  Loading,
  Button,
  Breadcrumb,
  SEO,
  SocialMedia,
} from '../../ui'

const ProductsComparisonView = ({
  productObjToCompare,
  onAddToCompare,
  onClearCompare,
  pageReady,
  isLoading,
  compareData,
  shareUrl,
  shareImageUrl,
  maxNumberComparisonEcom,
}) => {
  const styles = useStyles({ maxNumberComparisonEcom })
  const { navigate } = useLink()
  const containerRef = useRef();

  const { t } = useTranslation()
  const [products, setProducts] = useState([])

  /**
   * handleContinueShopping
   * allow user back to PLP and continue shopping
   */
  const titleToShare = t('screens.productsComparison.title')

  const handleContinueShopping = () => {
    navigate('/products/')
  }

  function getProductDetailUrl({
    colorOptionId: linkColorOptionId,
    productId: linkProductId,
    url: linkUrl,
  }) {
    return getProductUrl({
      productId: linkProductId,
      colorOptionId: linkColorOptionId,
      url: linkUrl,
    })
  }

  const handleGoToDetail = (e, sku) => {
    e.preventDefault()
    const productDetailUrl = getProductDetailUrl({
      colorOptionId: _.get(sku, 'colorOption.id'),
      productId: _.get(sku, 'product.id'),
      url: _.get(sku, 'product.canonicalHref'),
    })
    navigate(productDetailUrl)
  }

  const breadcrumb = [{
    text: t('screens.productsComparison.breadcrumb.all'),
    url: '/products',
  }, {
    text: t('screens.productsComparison.breadcrumb.children'),
    url: '',
  }]

  useEffect(() => {
    setProducts(productObjToCompare)
  }, [productObjToCompare])

  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {/* TODO: add seo description and meta */}
      <SEO
      // description={seoDescription}
        title="needs"
      />
      <StickyContainer>
        <div className={styles.containerFluid}>

          {
            _.isEmpty(compareData) && !isLoading
              ? (
                <EmptyPage
                  styles={styles}
                  handleContinueShopping={handleContinueShopping}
                  t={t}
                />
              ) : (
                pageReady ? (
                  <div className={styles.container}>
                    <Breadcrumb classN={styles.breadcrumb} links={breadcrumb} />
                    <div className={styles.headerComparison}>
                      <p className={styles.textItemsSelected}>
                        <Trans
                          i18nKey="screens.productsComparison.itemsSelected"
                        >
                          You have selected
                          {' '}
                          <b>
                            {{ items: compareData.length }}
                            /
                            {{ maxvalue: maxNumberComparisonEcom }}
                          </b>
                          {' '}
                          to compare.
                        </Trans>
                      </p>
                      <Button
                        className={styles.buttonClear}
                        text={t('ui.modalCompare.clearAll')}
                        onClick={onClearCompare}
                      />
                    </div>
                    <div
                      className={styles.rowProducts}
                    >
                      <Products
                        calculatedHeight={null}
                        products={products}
                        onAddToCompare={onAddToCompare}
                        t={t}
                      />
                    </div>
                    <Sticky
                      topOffset={315}
                      disableHardwareAcceleration
                      disableCompensation
                    >
                      {({
                        style,
                        isSticky,
                        distanceFromBottom,
                      }) => (
                        <div
                          className={
                            combineClassNames(
                              [styles.rowPictures,
                                isSticky ? styles.rowSticky : '',
                                distanceFromBottom < 50 ? styles.botton : '',
                              ],
                            )
                          }
                          style={{
                            ...style,
                          }}
                        >
                          <RowProductsTop
                            isSticky={isSticky}
                            products={products.products}
                            distanceFromBottom={distanceFromBottom}
                          />
                        </div>
                      )}
                    </Sticky>
                    <div ref={containerRef}>
                      <Ratting
                        products={products}
                        styles={styles}
                      />
                    </div>
                    <Features products={products} styles={styles} />
                    <Footer
                      products={products}
                      styles={styles}
                      t={t}
                      handleGoToDetail={handleGoToDetail}
                    />
                  </div>
                ) : (
                  <Loading />
                )
              )
                }
        </div>
      </StickyContainer>
      <SocialMedia
        scale="2"
        marginIcon="0 2rem 0 0"
        title={titleToShare}
        imageUrl={shareImageUrl}
        classN={styles.shareGroup}
        shareUrl={shareUrl}
      />
    </Layout>
  )
}

export default ProductsComparisonView
