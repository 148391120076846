import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 30,
    maxHeight: '10rem',
    backgroundColor: theme.colors.background,
    transition: 'top 400ms',
    width: '100%',
    top: -120,
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
    },
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100vw',
      padding: '0',
      maxWidth: '1130px',
    },
  },
  onTop: {
    top: 0,
    transition: 'top 400ms',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    },
  },

  image: {
    width: '7rem',
    height: '7rem',
    backgroundColor: '#dddddd',
    marginRight: '10px',
    '& img': {
      width: '100%',
    },
    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      marginRight: '15px',
    },
  },
  noImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      transform: ' scale(0.5)',
      margin: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: ' 1 1 calc(100% - 70%)',
    '& p, & span': {
      margin: 0,
      fontSize: 14,
      lineHeight: '18px',
    },
  },
  productCards: {
    flex: '1 1 calc(50vw - 40px)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 10px',

    [`@media (min-width: ${theme.mediaQueries.mobile}px)`]: {
      padding: '15px',
      maxWidth: '25%',
      flex: '1 1 25%',
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    color: theme.colors.text,
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 3,
    boxOrient: 'vertical',
  },
  originalPrice: {
    fontSize: '1.2rem',
    display: 'inline-block',
    textDecoration: 'line-through',
    color: theme.colors.disabled,
    lineHeight: 1.14,
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      marginRight: '0.4rem',
      display: 'inline',
      lineHeight: 1.8,
    },
  },
  sellPriceOnSale: {
    lineHeight: 1,
    color: theme.colors.alert,
    display: 'inline-block',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      lineHeight: 1.8,
    },
  },
}))

export default useStyles
