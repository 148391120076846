import _ from 'lodash'
import React from 'react'

const Features = ({ products, styles }) => _.map(products.features, (feature, id) => {
  // get number of products are selected
  const totalProducts = _.filter(products.products, (p) => _.isObject(p) === true).length - 1
  return (
    <div key={`feature-${id}`}>
      <div className={styles.row}>
        <div className={styles.columnTitle}>
          <h4>{_.get(feature, 'title', '')}</h4>
        </div>
      </div>
      <div className={styles.row}>
        {
          _.map(feature.values, (value, idx) => (
            <div className={styles.columnValue} key={`value-${idx}`}>
              <p>{totalProducts >= idx ? _.get(value, 'value', '—') : ''}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
})

export default Features
