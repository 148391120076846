/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React from 'react'
import { combineClassNames } from '../../../../helpers'
import IconPlus from '../../../../assets/icons/icon_plus.inline.svg'
import clearIcon from '../../../../assets/icons/icon_clear_grey.svg'
import useStyles from './products-style'
import { Button, Link } from '../../../../ui'
import Product from '../product'

const Products = ({
  products,
  onAddToCompare,
  isSticky,
  calculatedHeight,
  t,
}) => {
  const styles = useStyles()

  return (
    _.map(products.products, (sku) => (
      sku.id ? (

        <div
          key={`product-card-${sku.id}`}
          className={combineClassNames([styles.productCards, isSticky ? styles.productCardssStick : ''])}
        >
          <div className={styles.removeItem}>
            <Button
              inline
              onClick={() => onAddToCompare(sku)}
              iconImage={clearIcon}
              className={
                combineClassNames([styles.removeItemButton, isSticky ? styles.removeItemButtonStick : ''])
              }
            />
          </div>
          <Product
            isSticky={isSticky}
            imageUrl={
              _.get(sku, 'colorOption.images.0.versions.webLarge')
              || _.get(sku, 'colorOption.defaultImage.versions.webLarge')
            }
            title={_.get(sku, 'product.title')}
            sellPrice={_.get(sku, 'colorOption.sellPrice')}
            originalPrice={_.get(sku, 'colorOption.originalPrice')}
            activeCustomLabels={_.get(sku, 'colorOption.activeCustomLabels')}
            colorName={_.get(sku, 'colorOption.name')}
            colorOptionId={_.get(sku, 'colorOption.id')}
            productId={_.get(sku, 'product.id')}
            url={_.get(sku, 'product.canonicalHref')}
          />
        </div>
      ) : (
        <div
          className={
          combineClassNames([styles.emptyCards, isSticky ? styles.emptyCardsStick : ''])
          }
          key={`product-nocard-${sku}`}
        >
          <Link
            to="/products/"
            className={
              combineClassNames(
                [styles.boxEmptyCard, isSticky ? styles.boxEmptyCardStick : ''],
              )
            }
          >
            <div className={styles.contentEmptyCard}>
              <IconPlus />
              <p
                className={
                  combineClassNames([styles.textEmptyCard, isSticky ? styles.textEmptyCardStick : ''])
                }
              >
                {t('screens.productsComparison.add')}
              </p>
            </div>
          </Link>
        </div>
      )
    ))
  )
}

export default Products
