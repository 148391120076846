/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTheme } from 'react-jss'
import { useLink } from '../../../../hook'
import { getProductUrl, combineClassNames } from '../../../../helpers'
import {
  CurrencyFormat,
  CustomLables,
  Link,
} from '../../../../ui'
import useStyles from './product-style'
import placeholderImage from '../../../../assets/images/icon_image_placeholder.png'

const Product = ({
  isSticky,
  imageUrl,
  title,
  colorOptionId,
  productId,
  url,
  sellPrice,
  originalPrice,
  activeCustomLabels,
  colorName,
}) => {
  // prepare hooks
  const styles = useStyles()
  const { navigate } = useLink()
  const theme = useTheme()
  // states
  const [noImage, setNoImage] = useState(_.isEmpty(imageUrl))
  const productDetailUrl = getProductDetailUrl({
    colorOptionId,
    productId,
    url,
  })
  const onSale = originalPrice !== sellPrice
  const placeholder = _.get(theme, 'config.placeholderImage', placeholderImage)
  function getProductDetailUrl({
    colorOptionId: linkColorOptionId,
    productId: linkProductId,
    url: linkUrl,
  }) {
    return getProductUrl({
      productId: linkProductId,
      colorOptionId: linkColorOptionId,
      url: linkUrl,
    })
    // if (linkUrl) return linkUrl
    // return `/product/${linkProductId}${linkColorOptionId ? `?color=${linkColorOptionId}` : ''}`
  }

  const handleClick = (e) => {
    e.preventDefault()
    // when browser support javascript, use navigate function instead
    // then can handle external tracking in onClick callback
    navigate(productDetailUrl)

    // TODO: trigger event for tracking
  }
  useEffect(() => {
    if (_.isEmpty(imageUrl) !== noImage) {
      setNoImage(_.isEmpty(imageUrl))
    }
  }, [imageUrl])
  return (
    <div className={
      combineClassNames(
        [styles.container, isSticky ? styles.containerSticky : ''],
      )
    }
    >
      <figure className={
        combineClassNames(
          [styles.boxImage, isSticky ? styles.boxImageSticky : ''],
        )
      }
      >
        <Link
          to={productDetailUrl}
          onClick={handleClick}
          className={styles.link}
        >
          <div className={combineClassNames([styles.image, noImage && styles.noImage])}>
            <img
              src={noImage ? placeholder : imageUrl}
              alt={title}
              onError={() => {
                setNoImage(true)
              }}
            />
          </div>
        </Link>
      </figure>
      <div className={
        combineClassNames(
          [styles.boxContent, isSticky ? styles.boxContentSticky : ''],
        )
      }
      >
        <div
          className={
            combineClassNames(
              [styles.customLabels, isSticky ? styles.customLabelsSticky : ''],
            )
          }
        >
          <CustomLables
            customLabels={[_.head(activeCustomLabels)]}
            frontendPosition="1"
          />
        </div>
        <p className={styles.title}>{title}</p>
        <p className={
          combineClassNames(
            [styles.colorSelect, isSticky ? styles.colorSelectSticky : ''],
          )
        }
        >
          {colorName}
        </p>
        <p className={styles.price}>
          {onSale && (
            <CurrencyFormat
              className={styles.originalPrice}
              value={originalPrice}
            />
          )}
          <CurrencyFormat
            className={combineClassNames([styles.sellPrice, onSale && styles.sellPriceOnSale])}
            value={sellPrice}
          />
        </p>
      </div>
    </div>

  )
}

export default Product
